<template>
    <div class="m-0 px-3 pt-4 pb-0 pr-2 manage-shop-show w-75" v-loading="loaders.fetching">
        <back-button class="ml-4" />
        <div class="ml-2 d-flex justify-content-between">
            <div>
                <h1>Bariatric Coordinator</h1>
            </div>
        </div>
        <bariatric-coordinator v-if="bariatricCoordinator" :bariatric-coordinator="bariatricCoordinator" />
    </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import BariatricCoordinator from "@/views/Components/SuperAdmin/BariatricCoordinator/Card";

export default {
    components: {
        BackButton,
        BariatricCoordinator
    },
    data() {
        return {
            loaders: {
                fetching: false,
            },
            bariatricCoordinator: null,
        };
    },
    mounted() {
        this.getBariatricCoordinator();
    },
    methods: {
        async getBariatricCoordinator() {
            this.loaders.fetching = true;
            try {
                let response = await this.$store.dispatch('_getBariatricCoordinator');
                let {
                    data: { data },
                } = response;
                this.bariatricCoordinator = data;
            } catch (error) {

            }
            this.loaders.fetching = false;
        },
    },
};
</script>
<style scoped lang="scss"></style>
  